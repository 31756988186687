$main: #8dbfc6;

.home-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-header {
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    padding: 16px 32px;
    border-bottom: 1px solid #ffffff33;
    background-color: $main;
  }

  .resv-root {
    width: 100%;
    padding: 60px 32px 35px;
    position: relative;
    overflow: visible;

    .user-name {
      font-size: 20px;
      color: #ffffff;

      span {
        margin-right: 8px;
      }
    }
    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .resv-title {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-size: 15px;
          color: #ffffff;
          margin-left: 8px;
        }
      }

      .delete-button {
        background-color: #fff;
        color: $main;
        font-size: 12px;
        padding: 6px 8px;
      }
    }

    .resv-list {
      width: calc(100vw - 64px);
      padding: 16px;
      margin: 10px 0;
      background-color: #ffffff;
      border: 1px solid rgb(240, 240, 240);
      box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);

      span {
        white-space: pre-line;

        &.resv-no-list {
          text-align: center;
          font-size: 15px;
          color: #888888;
        }

        &.resv-list-item {
          width: 100%;
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            margin-right: 5px;
            color: rgb(118, 118, 118);

            &.item-room {
              color: rgb(25, 25, 25);
              margin-left: auto;
              font-weight: 800;
              margin-right: 0;
            }
          }
        }
      }
    }

    .add-resv-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw - 64px);
      padding: 13px;
      color: $main;
      background-color: #fff;
      border: 1px solid $main;
      font-size: 16px;

      img {
        position: absolute;
        right: 10px;
        width: 16px;
        height: 16px;
      }
    }

    .background {
      position: absolute;
      background-color: $main;
      top: 0;
      left: 0;
      width: 100vw;
      height: 58%;
      z-index: -1;
    }
  }

  .setting-root {
    width: calc(100vw - 64px);
    flex: 1;
    background-color: #ffffff;

    .setting-title-wrapper {
      display: flex;
      align-items: center;
      .title {
        margin-left: 6px;
        font-weight: 800;
      }
    }

    .setting-items-wrapper {
      margin: 12px 0 0;
      padding: 24px 16px;
      border: 1px solid rgb(240, 240, 240);
      display: flex;
      flex-direction: column;
      box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);

      .setting-item {
        display: flex;
        flex-direction: column;

        .item-title {
          font-weight: 600;
        }

        .item-value {
          display: flex;
          justify-content: space-between;
          padding-bottom: 9px;
          border-bottom: 1px solid rgb(240, 240, 240);
          margin: 24px 0;

          .change-item-btn {
            color: $main;
          }
        }

        &.password {
          flex-direction: row;
          align-self: flex-end;
          justify-content: center;
          color: $main;

          img {
            width: 10px;
            height: 12px;
            margin-right: 12px;
          }
        }
      }
    }

    .setting-btns-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      margin: 40px 0;

      .delete-account-btn {
        padding: 16px;
        background-color: #fff;
        border: 1px solid $main;
        color: $main;
        font-size: 16px;
      }

      .logout-btn {
        padding: 16px;
        border: 1px solid $main;
        background-color: $main;
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
