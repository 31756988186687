.cheader-root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 56px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background-color: #fff;

  .back-btn-section {
    position: absolute;
    left: 32px;
    top: 14px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #191919;
  }
}
