.policy-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60%;

    img {
      margin-bottom: 12px;
    }

    .label-text {
      text-align: center;
      font-size: 18px;
      color: rgb(118, 118, 118);
    }
  }

  .button-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;

    .check-item {
      width: 80vw;
      margin-bottom: 24px;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .check-wrapper {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
        }

        span {
          font-weight: 14px;
          color: #333333;
        }
      }

      button {
        background-color: #fff;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .next-step-button {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80vw;
      height: 48px;
      font-size: 16px;
      background-color: #8dbfc6;
      color: #f0f0f0;

      &:disabled {
        background-color: #f0f0f0;
        color: #767676;
      }
    }
  }
}
