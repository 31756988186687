.id-popup-root {
  width: calc(100vw - 64px);
  background-color: #fff;
}

.id-popup-root .popup-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  padding: 17px;
  color: #8dbfc6;
  border-bottom: 1px solid #e1e1e1;
}

.id-popup-root .popup-content {
  padding: 25px 16px;
}

.id-popup-root .popup-content input {
  border: 1px solid #767676;
  width: 100%;
  padding: 15px 20px;
  font-size: 14px;
}

.id-popup-root .popup-content input::-webkit-input-placeholder {
  color: #767676;
}

.id-popup-root .popup-content input:-ms-input-placeholder {
  color: #767676;
}

.id-popup-root .popup-content input::-ms-input-placeholder {
  color: #767676;
}

.id-popup-root .popup-content input::placeholder {
  color: #767676;
}

.id-popup-root .popup-content input.error {
  border: 1px solid #dc0000;
}

.id-popup-root .popup-content .error-msg {
  display: inline-block;
  color: #dc0000;
  font-size: 12px;
  margin: 5px 0;
}

.id-popup-root .popup-footer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.id-popup-root .popup-footer button {
  padding: 15px;
  font-size: 16px;
}

.id-popup-root .popup-footer .cancel-button {
  color: #767676;
  background-color: #e1e1e1;
}

.id-popup-root .popup-footer .change-button {
  background-color: #8dbfc6;
  color: #fff;
}
