p.p1 {
  margin: 0px 0px 0px 0px;
  font: 13.3px AppleMyungjo;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p2 {
  margin: 0px 0px 0px 0px;
  font: 12px Times;
  color: #000000;
  -webkit-text-stroke: #000000;
  min-height: 14px;
}
p.p3 {
  margin: 0px 0px 0px 0px;
  font: 13.3px Times;
  color: #000000;
  -webkit-text-stroke: #000000;
  min-height: 16px;
}
p.p4 {
  margin: 0px 0px 0px 0px;
  font: 13.3px Times;
  color: #000000;
  -webkit-text-stroke: #000000;
}
span.s1 {
  font: 13.3px Times;
  font-kerning: none;
}
span.s2 {
  font-kerning: none;
}
span.s3 {
  font: 13.3px AppleMyungjo;
  font-kerning: none;
}
span.s4 {
  font-kerning: none;
  color: #0b4cb4;
  -webkit-text-stroke: 0px #0b4cb4;
}
span.s5 {
  font: 13.3px 'Lucida Grande';
  font-kerning: none;
}

.personal-root {
  flex: 1;
  padding: 108px 24px 48px 24px;
}

.personal-root .cheader-root {
  left: 0px;
}
