.findpassword-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.findpassword-root .content-root {
  margin-top: 56px;
  padding-top: 40px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.findpassword-root .content-root .input-item {
  margin-bottom: 20px;
}

.findpassword-root .content-root .input-item .input-label {
  font-size: 15px;
  color: #8dbfc6;
  margin-bottom: 8px;
}

.findpassword-root .content-root .done-btn {
  margin-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80vw;
  height: 48px;
  font-size: 16px;
}

.findpassword-root .content-root .done-btn.active {
  background-color: #8dbfc6;
  color: #f0f0f0;
}

.findpassword-root .content-root .done-btn.deactive {
  background-color: #f0f0f0;
  color: #767676;
}
