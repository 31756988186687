.cinput-root {
  height: 68px;
  position: relative;
  display: flex;
  flex-direction: column;

  .cinput-input {
    width: 80vw;
    border-bottom: 2px solid #8dbfc6;
  }

  .cinput-alert-message {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    margin-top: 4px;
    color: #dc0000;
  }

  .cinput-error-icon {
    position: absolute;
    right: 0;
    top: 11px;
  }
}
