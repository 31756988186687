#root {
  .popup-backdrop {
    width: 100vw;
    height: 100vh;
    background-color: #23232388;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
  }
}
