$main: #8dbfc6;

.date-popup-root {
  width: calc(100vw - 64px);
  background-color: #fff;

  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 17px;
    color: $main;
    border-bottom: 1px solid rgb(225, 225, 225);
  }

  .popup-content {
    padding: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 14px;
    }
  }

  .popup-footer {
    button {
      padding: 15px;
      font-size: 16px;
      color: white;
      background-color: $main;
      width: 100%;
    }
  }
}
