.reservation-root {
  width: 100%;
  .content-wrapper {
    margin-top: 56px;
    padding: 46px 32px;
    width: 100%;

    .form-item {
      .form-item-label {
        font-weight: bold;
        font-size: 15px;
        color: #191919;
        margin-bottom: 10px;
      }
      .select-container {
        border: 1px solid #f0f0f0;
        margin-bottom: 32px;
        box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
        margin-right: 3px;

        .select-box {
          &:not(:last-child) {
            border-bottom: 1px solid #f0f0f0;
          }
          display: flex;
          justify-content: space-between;
          padding: 10px 10px;
          font-size: 15px;
          color: #191919;
        }

        .disabled {
          color: #191919;
          background-color: rgb(225, 225, 225);
          img {
            display: none;
          }
        }
        .input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0px;

          input {
            background-color: #fff;
            padding: 10px 0px;
            font-size: 15px;
            color: #191919;

            &::-webkit-calendar-picker-indicator {
              display: none;
              -webkit-appearance: none;
            }
          }

          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
      }
    }

    .register-button {
      &:disabled {
        color: rgb(118, 118, 118);
        background-color: rgb(225, 225, 225);
      }
      width: 100%;
      height: 48px;
      background-color: #8dbfc6;
      position: fixed;
      left: 0px;
      bottom: 0px;
      color: #fff;
      font-size: 16px;
    }
  }
}
