.login-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 14px;
}

.login-root .login-logo-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login-root .login-logo-section .login-logo-icon {
  width: 114px;
  margin: 16px;
}

.login-root .login-logo-section span {
  font-size: 16px;
  color: #767676;
}

.login-root .login-input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.login-root .login-input-section .input-wrapper {
  position: relative;
  height: 68px;
}

.login-root .login-input-section .input-wrapper .input-logo {
  position: absolute;
  left: 0;
  top: 18px;
}

.login-root .login-input-section .input-wrapper .login-input {
  width: 80vw;
  height: 48px;
  padding: 0px 25px;
  font-size: 15px;
  color: #191919;
}

.login-root .login-input-section .input-wrapper .input-error-logo {
  position: absolute;
  right: 0;
  top: 18px;
}

.login-root .login-input-section .input-wrapper .alert-message {
  font-size: 12px;
  position: absolute;
  color: #de0000;
  bottom: 0;
  left: 0;
}

.login-root .login-input-section .login-button {
  width: 80vw;
  background-color: #8dbfc6;
  color: #ffffff;
  font-size: 16px;
  padding: 13px;
  margin-top: 28px;
}

.login-root .login-input-section .login-route-section {
  width: 80vw;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.login-root .login-input-section .login-route-section button {
  background-color: #ffffff;
  margin-bottom: 14px;
}

.login-root .login-input-section .login-route-section button span {
  font-size: 14px;
  margin-right: 4px;
}

.login-root .bottom-logo-section span {
  color: #767676;
  margin-right: 7px;
  font-size: 11px;
}
