.sign-up-root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .content-root {
    margin-top: 56px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .input-item {
      margin-bottom: 20px;
      .input-label {
        font-size: 15px;
        color: #8dbfc6;
        margin-bottom: 8px;
      }

      &.input-email {
        position: relative;

        .cinput-error-icon {
          display: none;
        }

        .email-verify-btn {
          position: absolute;
          font-size: 16px;
          color: #8dbfc6;
          bottom: 40px;
          right: 0;

          &.deactive {
            display: none;
          }
        }
      }

      &.input-verify-code {
        position: relative;

        .confirm-btn {
          position: absolute;
          font-size: 16px;
          color: #8dbfc6;
          bottom: 40px;
          right: 0;
        }
      }
    }

    .done-btn {
      margin-top: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80vw;
      height: 48px;
      font-size: 16px;

      &.active {
        background-color: #8dbfc6;
        color: #f0f0f0;
      }

      &.deactive {
        background-color: #f0f0f0;
        color: #767676;
      }
    }
  }
}
