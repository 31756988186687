.cheader-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 56px;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background-color: #fff;
}

.cheader-root .back-btn-section {
  position: absolute;
  left: 32px;
  top: 14px;
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cheader-root .title {
  font-size: 20px;
  font-weight: 600;
  color: #191919;
}
