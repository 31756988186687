$main: #8dbfc6;

.delete-popup-root {
  width: calc(100vw - 64px);
  background-color: #fff;

  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 17px;
    color: $main;
    border-bottom: 1px solid rgb(225, 225, 225);
  }

  .popup-content {
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 20px;
      strong {
        color: rgb(220, 0, 0);
        font-weight: 500;
      }
    }
  }

  .popup-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      padding: 15px;
      font-size: 16px;
    }

    .cancel-button {
      color: rgb(118, 118, 118);
      background-color: rgb(225, 225, 225);
    }

    .change-button {
      background-color: $main;
      color: #fff;
    }
  }
}
