.login-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 14px;

  .login-logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-logo-icon {
      width: 114px;
      margin: 16px;
    }

    span {
      font-size: 16px;
      color: rgb(118, 118, 118);
    }
  }

  .login-input-section {
    display: flex;
    flex-direction: column;

    .input-wrapper {
      position: relative;
      height: 68px;

      .input-logo {
        position: absolute;
        left: 0;
        top: 18px;
      }

      .login-input {
        width: 80vw;
        height: 48px;
        padding: 0px 25px;
        font-size: 15px;
        color: #191919;
      }

      .input-error-logo {
        position: absolute;
        right: 0;
        top: 18px;
      }

      .alert-message {
        font-size: 12px;
        position: absolute;
        color: #de0000;
        bottom: 0;
        left: 0;
      }
    }

    .login-button {
      width: 80vw;
      background-color: #8dbfc6;
      color: #ffffff;
      font-size: 16px;
      padding: 13px;
      margin-top: 28px;
    }

    .login-route-section {
      width: 80vw;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button {
        background-color: #ffffff;
        margin-bottom: 14px;

        span {
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }

  .bottom-logo-section {
    span {
      color: #767676;
      margin-right: 7px;
      font-size: 11px;
    }
  }
}
