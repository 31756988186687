.date-popup-root {
  width: calc(100vw - 64px);
  background-color: #fff;
}

.date-popup-root .popup-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  padding: 17px;
  color: #8dbfc6;
  border-bottom: 1px solid #e1e1e1;
}

.date-popup-root .popup-content {
  padding: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.date-popup-root .popup-content span {
  font-size: 14px;
}

.date-popup-root .popup-footer button {
  padding: 15px;
  font-size: 16px;
  color: white;
  background-color: #8dbfc6;
  width: 100%;
}
