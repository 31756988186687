.exit-popup-root {
  width: calc(100vw - 64px);
  background-color: #fff;
}

.exit-popup-root .popup-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  padding: 17px;
  color: #8dbfc6;
  border-bottom: 1px solid #e1e1e1;
}

.exit-popup-root .popup-content {
  padding: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.exit-popup-root .popup-content span {
  font-size: 14px;
}

.exit-popup-root .popup-footer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.exit-popup-root .popup-footer button {
  padding: 15px;
  font-size: 16px;
}

.exit-popup-root .popup-footer .cancel-button {
  color: #767676;
  background-color: #e1e1e1;
}

.exit-popup-root .popup-footer .change-button {
  background-color: #8dbfc6;
  color: #fff;
}
