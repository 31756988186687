.home-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home-root .home-header {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  padding: 16px 32px;
  border-bottom: 1px solid #ffffff33;
  background-color: #8dbfc6;
}

.home-root .resv-root {
  width: 100%;
  padding: 60px 32px 35px;
  position: relative;
  overflow: visible;
}

.home-root .resv-root .user-name {
  font-size: 20px;
  color: #ffffff;
}

.home-root .resv-root .user-name span {
  margin-right: 8px;
}

.home-root .resv-root .title-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.home-root .resv-root .title-section .resv-title {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home-root .resv-root .title-section .resv-title span {
  font-size: 15px;
  color: #ffffff;
  margin-left: 8px;
}

.home-root .resv-root .title-section .delete-button {
  background-color: #fff;
  color: #8dbfc6;
  font-size: 12px;
  padding: 6px 8px;
}

.home-root .resv-root .resv-list {
  width: calc(100vw - 64px);
  padding: 16px;
  margin: 10px 0;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
}

.home-root .resv-root .resv-list span {
  white-space: pre-line;
}

.home-root .resv-root .resv-list span.resv-no-list {
  text-align: center;
  font-size: 15px;
  color: #888888;
}

.home-root .resv-root .resv-list span.resv-list-item {
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home-root .resv-root .resv-list span.resv-list-item span {
  margin-right: 5px;
  color: #767676;
}

.home-root .resv-root .resv-list span.resv-list-item span.item-room {
  color: #191919;
  margin-left: auto;
  font-weight: 800;
  margin-right: 0;
}

.home-root .resv-root .add-resv-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100vw - 64px);
  padding: 13px;
  color: #8dbfc6;
  background-color: #fff;
  border: 1px solid #8dbfc6;
  font-size: 16px;
}

.home-root .resv-root .add-resv-btn img {
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
}

.home-root .resv-root .background {
  position: absolute;
  background-color: #8dbfc6;
  top: 0;
  left: 0;
  width: 100vw;
  height: 58%;
  z-index: -1;
}

.home-root .setting-root {
  width: calc(100vw - 64px);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #ffffff;
}

.home-root .setting-root .setting-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home-root .setting-root .setting-title-wrapper .title {
  margin-left: 6px;
  font-weight: 800;
}

.home-root .setting-root .setting-items-wrapper {
  margin: 12px 0 0;
  padding: 24px 16px;
  border: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
}

.home-root .setting-root .setting-items-wrapper .setting-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.home-root .setting-root .setting-items-wrapper .setting-item .item-title {
  font-weight: 600;
}

.home-root .setting-root .setting-items-wrapper .setting-item .item-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid #f0f0f0;
  margin: 24px 0;
}

.home-root .setting-root .setting-items-wrapper .setting-item .item-value .change-item-btn {
  color: #8dbfc6;
}

.home-root .setting-root .setting-items-wrapper .setting-item.password {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #8dbfc6;
}

.home-root .setting-root .setting-items-wrapper .setting-item.password img {
  width: 10px;
  height: 12px;
  margin-right: 12px;
}

.home-root .setting-root .setting-btns-wrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin: 40px 0;
}

.home-root .setting-root .setting-btns-wrapper .delete-account-btn {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #8dbfc6;
  color: #8dbfc6;
  font-size: 16px;
}

.home-root .setting-root .setting-btns-wrapper .logout-btn {
  padding: 16px;
  border: 1px solid #8dbfc6;
  background-color: #8dbfc6;
  color: #fff;
  font-size: 16px;
}
