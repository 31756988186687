.reservation-root {
  width: 100%;
}

.reservation-root .content-wrapper {
  margin-top: 56px;
  padding: 46px 32px;
  width: 100%;
}

.reservation-root .content-wrapper .form-item .form-item-label {
  font-weight: bold;
  font-size: 15px;
  color: #191919;
  margin-bottom: 10px;
}

.reservation-root .content-wrapper .form-item .select-container {
  border: 1px solid #f0f0f0;
  margin-bottom: 32px;
  -webkit-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.03);
  margin-right: 3px;
}

.reservation-root .content-wrapper .form-item .select-container .select-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 10px;
  font-size: 15px;
  color: #191919;
}

.reservation-root .content-wrapper .form-item .select-container .select-box:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.reservation-root .content-wrapper .form-item .select-container .disabled {
  color: #191919;
  background-color: #e1e1e1;
}

.reservation-root .content-wrapper .form-item .select-container .disabled img {
  display: none;
}

.reservation-root .content-wrapper .form-item .select-container .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 0px;
}

.reservation-root .content-wrapper .form-item .select-container .input-wrapper input {
  background-color: #fff;
  padding: 10px 0px;
  font-size: 15px;
  color: #191919;
}

.reservation-root .content-wrapper .form-item .select-container .input-wrapper input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.reservation-root .content-wrapper .form-item .select-container .input-wrapper img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.reservation-root .content-wrapper .register-button {
  width: 100%;
  height: 48px;
  background-color: #8dbfc6;
  position: fixed;
  left: 0px;
  bottom: 0px;
  color: #fff;
  font-size: 16px;
}

.reservation-root .content-wrapper .register-button:disabled {
  color: #767676;
  background-color: #e1e1e1;
}
