.findpassword-root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .content-root {
    margin-top: 56px;
    padding-top: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-item {
      margin-bottom: 20px;

      .input-label {
        font-size: 15px;
        color: #8dbfc6;
        margin-bottom: 8px;
      }
    }

    .done-btn {
      margin-top: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80vw;
      height: 48px;
      font-size: 16px;

      &.active {
        background-color: #8dbfc6;
        color: #f0f0f0;
      }

      &.deactive {
        background-color: #f0f0f0;
        color: #767676;
      }
    }
  }
}
