$main: #8dbfc6;

.name-popup-root {
  width: calc(100vw - 64px);
  background-color: #fff;

  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 17px;
    color: $main;
    border-bottom: 1px solid rgb(225, 225, 225);
  }

  .popup-content {
    padding: 25px 16px;

    input {
      border: 1px solid rgb(118, 118, 118);
      width: 100%;
      padding: 15px 20px;
      font-size: 14px;

      &::placeholder {
        color: rgb(118, 118, 118);
      }
    }
  }

  .popup-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      padding: 15px;
      font-size: 16px;
    }

    .cancel-button {
      color: rgb(118, 118, 118);
      background-color: rgb(225, 225, 225);
    }

    .change-button {
      background-color: $main;
      color: #fff;
    }
  }
}
